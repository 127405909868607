import { Logout, Person2Rounded, Settings } from '@mui/icons-material';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { Box, Button, Divider, IconButton, ListItem, Stack, Tooltip, Typography, lighten, styled, useTheme } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { AbilityGuard } from 'src/Guards/abilityGuard/AbilityGuard';
import Logo from 'src/components/LogoSign';
import SystemStatus from 'src/components/systemStatus/SystemStatus';
import UserSettings from 'src/components/userSettings/UserSettings';
import { ABILITY_TYPES, ABILITY_PAGES } from 'src/config/ability';
import { useAuth } from 'src/features/authentication';
import { useSidebarStore } from 'src/store/sidebar/sidebarStore';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const LogoAnimationBox = styled(Box)(() => ({
    animation: 'logoShowSideBar 1s ease 0s 1 normal forwards',
    '@keyframes logoShowSideBar': {
        '0%': {
            opacity: 0,
            transform: 'rotateY(100deg)',
            transformOrigin: 'left',
        },
        '100%': {
            opacity: 1,
            transform: 'rotateY(0)',
            transformOrigin: 'left',
        },
    },
}));

const NavButton = styled(Button)(({ theme }) => ({
    justifyContent: 'left',
    '&.active': {
        border: `1px solid ${theme.palette.primary.main}`,
        animation: 'borderAnimation 2s ease',
    },
    '&:hover': {
        transform: 'scale(1.02)',
    },
    '@keyframes borderAnimation': {
        '0%': {
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
        },
        '20%': {
            borderTopColor: lighten(theme.palette.secondary.main, 0.5),
        },
        '50%': {
            borderLeftColor: lighten(theme.palette.secondary.main, 0.5),
        },
        '100%': {
            borderRightColor: theme.palette.secondary.main,
        },
    },
})) as typeof Button;

interface Props {
    shouldCloseOnClick?: boolean;
}

export default function SidebarContentAdmin({ shouldCloseOnClick = false }: Props) {
    const theme = useTheme();
    const { logout } = useAuth()
    const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
    const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);

    const handleNavButtonClick = () => {
        if (shouldCloseOnClick) {
            toggleSidebar()
        }
    }

    const handleLogout = () => {
        console.log('logout')
        try {
            logout()
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {isSidebarOpen &&
                <DrawerHeader id='sidebar-logo' sx={{ background: `linear-gradient(0deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.main} 80%)` }}>
                    <LogoAnimationBox>
                        <Logo width={250} />
                    </LogoAnimationBox>
                </DrawerHeader>
            }

            <Divider
                textAlign='right'
                id='sidebar-layout-divider'
                sx={{
                    mx: 1,
                    mt: isSidebarOpen ? 2 : `calc(${theme.header.height} + 20px)`,
                    alignItems: 'center'
                }}
            >
                {/* <HiddenWrapper mobileOnly>
                    <ThemeSwitch />
                </HiddenWrapper> */}

                <IconButton
                    color='secondary'
                    aria-label="open drawer"
                    onClick={() => toggleSidebar()}
                >
                    {isSidebarOpen ? <FirstPageRoundedIcon fontSize='medium' color='primary' id='sidebar-layout-close' /> : <LastPageRoundedIcon fontSize='medium' color='primary' id='sidebar-layout-open' />}
                </IconButton>
            </Divider>

            <Stack direction='column' justifyContent='space-between' sx={{ mt: 1.5, height: '100%' }}>
                <div>
                    {!isSidebarOpen && <Divider sx={{ my: 1, mx: 1 }} />}
                    <ListItem component='div'>
                        <AbilityGuard i={ABILITY_TYPES.VISIT} a={ABILITY_PAGES.ADMIN_JOBS}>
                            <NavButton
                                fullWidth
                                component={RouterLink}
                                to='/admin/jobs'
                                onClick={() => handleNavButtonClick()}
                                startIcon={
                                    <Tooltip title='Jobs' placement='right'>
                                        <WorkTwoToneIcon color='secondary' />
                                    </Tooltip>
                                }
                            >
                                {isSidebarOpen ?
                                    <Typography color='secondary' variant='h6'>Jobs</Typography>
                                    : null
                                }
                            </NavButton>
                        </AbilityGuard>
                    </ListItem>
                    <ListItem component='div'>
                        <AbilityGuard i={ABILITY_TYPES.VISIT} a={ABILITY_PAGES.ADMIN_USERS}>
                            <NavButton
                                fullWidth
                                component={RouterLink}
                                to='/admin/users'
                                onClick={() => handleNavButtonClick()}
                                startIcon={
                                    <Tooltip title='Users' placement='right'>
                                        <GroupTwoToneIcon color='secondary' />
                                    </Tooltip>
                                }
                            >
                                {isSidebarOpen ?
                                    <Typography color='secondary' variant='h6'>Users</Typography>
                                    : null
                                }
                            </NavButton>
                        </AbilityGuard>
                    </ListItem>
                    <ListItem component='div'>
                        <AbilityGuard i={ABILITY_TYPES.VISIT} a={ABILITY_PAGES.ADMIN_CROWDS}>
                            <NavButton
                                fullWidth
                                component={RouterLink}
                                to='/admin/crowds'
                                onClick={() => handleNavButtonClick()}
                                startIcon={
                                    <Tooltip title='Crowds' placement='right'>
                                        <Groups2TwoToneIcon color='secondary' />
                                    </Tooltip>
                                }
                            >
                                {isSidebarOpen ?
                                    <Typography color='secondary' variant='h6'>Crowds</Typography>
                                    : null
                                }
                            </NavButton>
                        </AbilityGuard>
                    </ListItem>
                    <ListItem component='div'>
                        <AbilityGuard i={ABILITY_TYPES.VISIT} a={ABILITY_PAGES.ADMIN_SKILLS}>
                            <NavButton
                                fullWidth
                                component={RouterLink}
                                to='/admin/skills'
                                onClick={() => handleNavButtonClick()}
                                startIcon={
                                    <Tooltip title='Skills' placement='right'>
                                        <CategoryTwoToneIcon color='secondary' />
                                    </Tooltip>
                                }
                            >
                                {isSidebarOpen ?
                                    <Typography color='secondary' variant='h6'>Skills</Typography>
                                    : null
                                }
                            </NavButton>
                        </AbilityGuard>
                    </ListItem>
                </div>
                <div>
                    {/* <ListItem component='div' >
                        <UserSettings />
                    </ListItem> */}
                    <ListItem component='div' >
                        <Button
                            id='logout-button'
                            fullWidth
                            startIcon={<Logout color='secondary' />}
                            sx={{ justifyContent: 'left' }}
                            onClick={() => handleLogout()}
                        >
                            {isSidebarOpen ?
                                <Typography color='secondary' variant='h6'>Logout</Typography>
                                : null
                            }
                        </Button>

                    </ListItem>
                    <SystemStatus />
                </div>
            </Stack>
        </>
    );
}
