import { createContext } from "react";

export type AuthContextInterface = {
    user: any; //firebaseUser || auth0User || cognitoUser
    loading: boolean;
    error: any;
    isAuthenticated: boolean;
    getTokens: () => Promise<{ accessToken: string | null, refreshToken: string | null } | null>
    loginWithEmailAndPassword: (email: string, password: string, inApp?: boolean) => Promise<{ error: boolean } | void>;
    loginWithGoogle: () => Promise<void>;
    register: (email: string, newPassword: string, oldPassword: string, firstName: string, surName: string, comfirmPassword: string) => Promise<{ error: boolean, message: string }>;
    logout: () => Promise<void>;
    refreshToken: () => Promise<void>;
    verifyEmail: () => Promise<void>;
    resetPassword: (email: string) => Promise<any>;
    resetPasswordConfirm: (email: string, verificationCode: string, newPassword: string) => Promise<any>;
    emailVerified: boolean;
    authHook?: any
}

export const AuthContext = createContext<AuthContextInterface>({
    user: null,
    loading: false,
    error: undefined,
    isAuthenticated: false,
    getTokens: () => new Promise(resolve => null),
    loginWithEmailAndPassword: (email: string, password: string, inApp?: boolean) => new Promise(resolve => { }),
    loginWithGoogle: () => new Promise(resolve => { }),
    register: (email: string, newPassword: string, oldPassword: string, firstName: string, surName: string, comfirmPassword: string) => new Promise(resolve => { }),
    logout: () => new Promise(resolve => { }),
    refreshToken: () => new Promise(resolve => { }),
    verifyEmail: () => new Promise(resolve => { }),
    resetPassword: (email: string) => new Promise(resolve => { }),
    resetPasswordConfirm: (email: string, verificationCode: string, newPassword: string) => new Promise(resolve => { }),
    emailVerified: false,
});
