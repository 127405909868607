import * as Sentry from '@sentry/react';
import { wrapUseRoutes } from '@sentry/react';
import { useLocation, useRoutes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import router from 'src/router/router';
import { sentryConfig } from './config/sentry';
import AppProviders from './context/AppProviders';
import { useEffect } from 'react';
import { env } from './env';


const logs = localStorage.getItem('logs');
if (env.REACT_APP_DEPLOYMENT_ENV === "production" && logs !== 'true') {
    console.log = () => { };
}

// Initialize Sentry if not in localhost
if (env.REACT_APP_APP_BASE_URL.includes('localhost') || env.REACT_APP_DEPLOYMENT_ENV === "development") {
    console.log('Sentry NOT Enabled - localhost/development', env.REACT_APP_APP_BASE_URL)
} else {
    Sentry.init(sentryConfig);
    console.log('Sentry Enabled');
}

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const content = useSentryRoutes(router);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    // Providers are separated to make management easier
    // NOTE: if a provider A is dependant on the context of provider B, then provider B should be added within the index.tsx
    return <AppProviders>{content}</AppProviders>;
}

export default App;
