// import { CloseRounded } from '@mui/icons-material';
// import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetDownloadUrlQuery } from 'src/services/restApi/queries/fileUpload.service';
// import { useDialogStore } from 'src/store/dialog/dialogStore';
import { useWorkerInstructionsStore } from 'src/store/workerInstructionsStore/workerInstructionsStore';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface InstructionsProps {
    demo?: boolean
}

export default function Instructions({ demo = false }: InstructionsProps) {
    // const closeDrawer = useDrawerStore((state) => state.closeDrawer);
    const activeInstruction = useWorkerInstructionsStore((state) => state.activeInstruction);
    const information = useWorkerInstructionsStore((state) => state.information);
    const setInformation = useWorkerInstructionsStore((state) => state.setInformation);
    const [getDownloadURL, getDownloadURLResult] = useLazyGetDownloadUrlQuery();
    const [value, setValue] = useState(activeInstruction === 'primary' ? 0 : 1);
    const { jobId } = useParams<{ jobId: string }>();

    useEffect(() => {
        setValue(activeInstruction === 'primary' ? 0 : 1)
    }, [activeInstruction]);

    useEffect(() => {
        if (!demo) {
            //Just checking that it is showing the correct job information
            if (jobId !== information?.jobId) {
                setInformation(null)
            }
        }
    }, [information, jobId]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getInstructionFile = async (fileId: string) => {
        if (demo) {
            alert('Demo mode - no file uploaded yet. This item will on the worker screen')
        } else {
            const downloadUrl = await requestDownloadUrl(fileId);
            if (downloadUrl) {
                window.open(downloadUrl, "_blank", "noopener,noreferrer");
            }
        }
    };

    const requestDownloadUrl = async (fileId: string) => {
        const downloadUrl = await getDownloadURL(fileId).unwrap()
            .then((res: any) => {
                return res.data.downloadUrl;
            })
            .catch((err: any) => {
                console.log("error");
                console.log(err.message);
            });
        return downloadUrl;
    };

    // MAYBE WE PUT THIS IN LATER IF WE NEED IT
    // const opeDialog = useDialogStore((state) => state.openDialog);
    // const closeDialog = useDialogStore((state) => state.closeDialog);

    // const handleOpenDrawer = () => {
    //     opeDialog({
    //         dialogContent: <Box maxWidth={'90vw'} position={'relative'}>
    //             <Box position={'sticky'}   >
    //                 <IconButton onClick={() => closeDialog()} sx={{ position: 'absolute', zIndex: 1500, float: 'right', top: 0, right: 0 }} >
    //                     <CloseRounded />
    //                 </IconButton>
    //                 <Divider sx={{ pt: 4.3, position: 'relative' }} />
    //             </Box>

    //             <Box >
    //                 {activeInstruction === 'primary' ?
    //                     <Markdown>
    //                         {information?.instruction ?? 'No primary task instructions available'}
    //                     </Markdown>
    //                     :
    //                     <Markdown>
    //                         {information?.qaInstruction ?? 'No QA task instructions available'}
    //                     </Markdown>
    //                 }
    //             </Box>
    //         </Box>
    //     }
    //     );
    // }

    return (
        <Box sx={{ m: 1 }}>
            <Typography variant="h6" textAlign={'center'} color={'primary'}>
                {information?.name ?? 'Job Instructions'}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
            >
                <Tab label="Primary Instructions" {...a11yProps(0)} />
                <Tab label="QA Instructions" {...a11yProps(1)} />
            </Tabs>
            <Divider sx={{ my: 2 }} />
            <Box textAlign={'center'}>
                {
                    information?.instructionFileId && value === 0 &&
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        fullWidth
                        loading={getDownloadURLResult.isLoading}
                        onClick={() => getInstructionFile(information?.instructionFileId ?? '')}
                    >
                        Show more task instructions
                    </LoadingButton>
                }
                {
                    information?.qaInstructionFileId && value === 1 &&
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        fullWidth
                        loading={getDownloadURLResult.isLoading}
                        onClick={() => getInstructionFile(information?.qaInstructionFileId ?? '')}
                    >
                        Show more QA task instructions
                    </LoadingButton>
                }

            </Box>
            <Paper elevation={10} sx={{ p: 1, position: 'relative' }}>
                {/* MAYBE WE PUT THIS IN LATER IF WE NEED IT */}
                {/* <Box position={'absolute'} top={0} right={0} zIndex={1500}>
                    <IconButton onClick={handleOpenDrawer}>
                        <PreviewRoundedIcon />
                    </IconButton>
                </Box> */}
                <TabPanel value={value} index={0}>
                    <Markdown>
                        {information?.instruction ?? 'No primary task instructions available'}
                    </Markdown>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Markdown>
                        {information?.qaInstruction ?? 'No QA task instructions available'}
                    </Markdown>
                </TabPanel>
            </Paper>
        </Box>
    )
}
