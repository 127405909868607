import { initializeApp } from 'firebase/app';
import {
  AuthError,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword
} from 'firebase/auth';
import React, { createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { UserAuthState, useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import { AuthContext, AuthContextInterface } from '../../context/AuthContextInterface';
import firebaseConfig from './config/firebaseConfig';
import ApiClient from 'src/lib/axiosWrapper';
import { env } from 'src/env';

const app = initializeApp(firebaseConfig.config);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const setUserState = useUserAuthStore((state) => state.setUserState);

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      type roles = 'proteusAdmin' | 'proteusWorker' | 'proteusCrowdManager' | 'proteusViewOnly' | null
      const firebaseUser = await signInWithEmailAndPassword(auth, email, password);

      //TODO: This can be done better
      //Get role from claims
      const idTokenResult = await auth.currentUser?.getIdTokenResult()
      const claims = idTokenResult?.claims ?? {}
      const groups = claims['cognito:groups'] as roles[] ?? ['proteusWorker']
      const userId = claims['proteusId'] as string ?? ''

      const user: UserAuthState = {
        user: firebaseUser,
        userStatus: 'authenticated',
        accessToken: await firebaseUser.user.getIdToken(),
        refreshToken: firebaseUser.user.refreshToken,
        error: null,
        role: groups[0],
        userId: userId,
      };
      setUserState(user);
    } catch (error: any) {
      handleError(error);
    }
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      handleError(error);
    }
  };

  const register = async (email: string, newPassword: string, oldPassword: string, firstName: string, surName: string, comfirmPassword: string) => {

    try {

      //log in with temp password
      const currentUser = await signInWithEmailAndPassword(auth, email, oldPassword)

      if (currentUser.user) {
        //Update user first and last name
        const api = new ApiClient(env.REACT_APP_BASE_API_URL);
        const idTokenResult = await auth.currentUser?.getIdTokenResult()
        const claims = idTokenResult?.claims ?? {}
        const userId = claims['proteusId'] as string ?? ''

        const res = await api.patch(
          `1/User/${userId}/name`,
          {
            givenName: firstName,
            familyName: surName,
            status: 'CONFIRMED'
          },
          {
            headers: { Authorization: `Bearer ${await currentUser.user.getIdToken()}` },
          }
        ).catch(async (error) => {
          await auth.signOut()
          handleError(error.message);
          return { error: true, message: error.message };
        });


        //Update password to user set password
        await updatePassword(currentUser.user, newPassword)
          .then(async () => {
            logoutFirebase()
          })
          .catch(async (error) => {
            handleError(error.message);
            // we need to sign user out if we could not update their password
            await auth.signOut()
            return { error: true, message: error.message };
          });
      }
      return { error: true, message: '' };
    } catch (error: any) {
      handleError(error.message);
      return { error: true, message: 'Error Authenticating...' };
    }
  };

  const logout = async () => {
    try {
      logoutFirebase()
    } catch (error: any) {
      handleError(error);
    }
  };

  const refreshToken = async () => {
    try {
      const res = await refreshFirebaseToken()
    } catch (error: any) {
      logoutFirebase()
      handleError(error);
    }
  };

  const getTokens = async () => {
    const currentUser = auth.currentUser;
    try {
      return {
        accessToken: await currentUser?.getIdToken(true) ?? null,
        refreshToken: currentUser?.refreshToken ?? null
      }
    } catch (error: any) {
      handleError(error);
      return null
    }
  };

  const verifyEmail = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        await sendEmailVerification(currentUser);
      } catch (error: any) {
        handleError(error);
      }
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      handleError(error);
    }
  };

  const resetPasswordConfirm = async (email: string) => {
    throw new Error('Method not Implemented');
  };

  const handleError = (error: AuthError) => {
    toast.error('Error Authenticating...')
    console.log('Authentication Error:', error);
    throw new Error("Error Authenticating...");
  };

  const emailVerified = true //auth.currentUser ? auth.currentUser.emailVerified : false;

  const isAuthenticated = user ? true : false

  const authHook = useAuthState

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        error,
        getTokens,
        loginWithEmailAndPassword,
        loginWithGoogle,
        register,
        logout,
        refreshToken,
        verifyEmail,
        resetPassword,
        resetPasswordConfirm,
        emailVerified,
        authHook
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const refreshFirebaseToken = async () => {
  try {
    const auth = getAuth()
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Cannot get new token');
    }
    const res = {
      accessToken: await currentUser?.getIdToken(true),
      refreshToken: currentUser?.refreshToken,
    };
    useUserAuthStore.getState().setAccessToken(res.accessToken);
    useUserAuthStore.getState().setRefreshToken(res.refreshToken);
    return res;
  } catch (error) {
    return {
      refreshToken: null,
      accessToken: null,
    };
  }
}

export const logoutFirebase = async () => {
  const auth = getAuth()
  await signOut(auth);
  const userAuthStore = useUserAuthStore.getState();
  if (userAuthStore && userAuthStore.resetUserState) {
    userAuthStore.resetUserState();
  }
  window.location.href = '/auth/login'
}
