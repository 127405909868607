import { CognitoUser } from "amazon-cognito-identity-js";
import { _getCognitoPool } from "./_getCognitoPool";
import { _getCognitoUser } from "./_getCognitoUser";

function asyncPasswordForgotSendCode(cognitoUser: CognitoUser) {
  return new Promise(function (resolve, reject) {
    cognitoUser.forgotPassword(
      {
        onSuccess: function (data) {
          // successfully initiated reset password request
          resolve("Verification Code Sent");
          // console.log("CodeDeliveryData from forgotPassword: " + data);
        },
        onFailure: function (err) {
          reject(err);
        },
      },
      { App: "Proteus" }
    );
  });
}

interface ForgotPassDataProps {
  email: string;
}

const cognitoForgotPassword = async ({ email }: ForgotPassDataProps) => {
  const cognitoUser = _getCognitoUser(email)

  if (cognitoUser !== null) {
    try {
      const passwordForgotRes = await asyncPasswordForgotSendCode(cognitoUser);
      console.log("success");
      console.log(passwordForgotRes);
      return passwordForgotRes;
    } catch (error: any) {
      console.log("error");
      console.log(error);
      return {
        error: true,
        message: error.message,
      };
    }
  }
};

export { cognitoForgotPassword };
