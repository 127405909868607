import { InputAdornment, TextField, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const AppPassword = (props: any) => {
  const [passowrdShow, setPasswordShow] = useState(false);
  const toggleVisibility = () => {
    setPasswordShow(!passowrdShow);
  };
  return (
    <TextField
      {...props}
      type={passowrdShow ? "text" : "password"}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end" tabIndex={-1}>
            <IconButton onClick={toggleVisibility} tabIndex={-1}>
              {!passowrdShow ? (
                <VisibilityOff tabIndex={-1} />
              ) : (
                <Visibility tabIndex={-1} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default AppPassword;
