import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import HiddenWrapper from '../componentWrappers/HiddenWrapper';

export default function ToggleAppMode() {
    const userRole = useUserAuthStore((state) => state.role);
    const navigate = useNavigate()

    const role = userRole ? userRole : [];
    const siteSide = window.location.pathname.includes(
        '/admin/'
    ) ? 'admin' : 'worker';


    const handleSwitch = () => {
        if (
            window.location.pathname.includes(
                '/admin/'
            )
        ) {
            navigate('/worker/jobs')
        } else {
            navigate('/admin/jobs')
        }
    };

    return (role !== 'proteusWorker') && (

        <>
            <HiddenWrapper desktopOnly>
                <Button
                    onClick={() => handleSwitch()}
                    size="small"
                    color="inherit"
                    variant='outlined'
                    startIcon={<ExitToAppRoundedIcon />}
                >
                    {siteSide === "admin"
                        ? `Worker Site`
                        : `Admin Site`}
                </Button>
            </HiddenWrapper>
            <HiddenWrapper mobileOnly>
                <IconButton
                    onClick={() => handleSwitch()}
                    size="small"
                    color="inherit"
                >
                    {siteSide === "admin"
                        ? <ExitToAppRoundedIcon />
                        : <LoginRoundedIcon />}
                </IconButton>
            </HiddenWrapper>
        </>


    )

}
