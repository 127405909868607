import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useUserAuthStore } from '../userAuth/userAuthStore';

export enum themeNames {
  dark = 'NebulaFighterTheme',
  light = 'PureLightTheme',
  adminLight = 'AdminThemeLight',
  adminDark = 'AdminThemeDark',
  crowdManagerLight = 'CrowdManagerThemeLight',
  crowdManagerDark = 'CrowdManagerThemeDark',
  workerLight = 'WorkerThemeLight',
  workerDark = 'WorkerThemeDark',
  viewOnlyLight = 'ViewOnlyThemeLight',
  viewOnlyDark = 'ViewOnlyThemeDark',
}

export enum IThemeMode {
  dark = 'dark',
  light = 'light',
}

export interface ThemeState {
  theme: themeNames | string;
  themeMode: IThemeMode;

  setTheme: () => void;
  toggleThemeMode: () => void;
}

/**
 * Represents the theme store.
 */
export const useThemeStore = create<ThemeState>()(
  persist(
    (set, get) => ({
      /**
       * The current theme.
       */
      theme: themeNames.adminLight,

      /**
       * The current theme mode.
       */
      themeMode: IThemeMode.light,

      /**
       * Sets the theme to the specified value.
       * @param {themeNames | string} theme - The theme to set.
       */
      setTheme: () => {
        const role = useUserAuthStore.getState().role;
        const isDark = get().themeMode === IThemeMode.dark;

        if (location.pathname.includes('/auth/')) {
          //we just do this so that the login page is always light theme and the same for all users
          //we don't have different login pages per role
          set(() => ({
            theme: themeNames.adminLight,
          }))
        }
        if (location.pathname.includes('/worker/')) {
          set(() => ({
            theme: !isDark ? themeNames.workerLight : themeNames.workerDark,
          }))
        } else {
          if (role === 'proteusAdmin') {
            set(() => ({
              theme: !isDark ? themeNames.adminLight : themeNames.adminDark,
            }))
          }
          if (role === 'proteusCrowdManager') {
            set(() => ({
              theme: !isDark ? themeNames.crowdManagerLight : themeNames.crowdManagerDark,
            }))
          }
          if (role === 'proteusWorker') {
            set(() => ({
              theme: !isDark ? themeNames.workerLight : themeNames.workerDark,
            }))
          }
          if (role === 'proteusViewOnly') {
            set(() => ({
              theme: !isDark ? themeNames.viewOnlyLight : themeNames.viewOnlyDark,
            }))
          }
        }
      },

      /**
       * Toggles between the dark and light themes.
       */
      toggleThemeMode: () => {
        const isDark = get().themeMode === IThemeMode.dark;

        set(() => ({
          themeMode: isDark ? IThemeMode.light : IThemeMode.dark,
        }))
        get().setTheme();
      }
    }),
    // The key used to save the values of the store in localStorage.
    { name: 'themeStore' },
  ),
)
