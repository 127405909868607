export enum Endpoint {
    Crowd = 'Crowd',
    DataScripts = 'DataScripts',
    Export = 'Export',
    FileUpload = 'FileUpload',
    Job = 'Job',
    JobTask = 'JobTask',
    Language = 'Language',
    Roles = 'Roles',
    Skill = 'Skill',
    User = 'User',
    UserJob = 'UserJob',
    SystemStatus = 'SystemStatus',
    Question = 'Question',
    QaTask = 'QaTask'
}


export enum TagId {
    List = 'List',
    Detail = 'Detail',
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
}

// This is the endpoints that are used in the application
// It is used in the query/mutation and will be fixed to the base url
// i.e {BASE_URL}/users
export enum ENDPOINT {
    USERS = '/users',
    POSTS = '/posts',
}

export enum TAG_ID {
    PARTIAL_LIST = 'PARTIAL-LIST'
}

// Tags used to validate and invalidate cache objects made by RTK Query
export enum TAG {
    SKILLS = 'Skills',
    SKILL = 'Skill',
    CROWDS = 'Crowds',
    CROWD = 'Crowd',
    CROWD_USERS = 'Crowd-users',
    JOB = 'Job',
    SYSTEM_STATUS = 'SystemStatus',
    USER = 'User',
    USER_JOB = 'UserJob',
    QUESTION = 'Question',
    QA_TASK = 'QaTask',
    LANGUAGE = 'Language',
    ROLES = 'Roles'
}

export const TAG_TYPES: readonly string[] = Object.values(TAG);
