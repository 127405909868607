import { Suspense, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { useAuth } from 'src/features/authentication';
import { useThemeStore } from 'src/store/theme/themeStore';
import { UserAuthState, useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import * as Sentry from "@sentry/react";

export default function AuthGuard({ children }: { children: JSX.Element }) {
    try {
        const userSlice: UserAuthState = useUserAuthStore((state) => state.user);
        const role = useUserAuthStore((state) => state.role);
        const userId = useUserAuthStore((state) => state.userId);
        const setUserState = useUserAuthStore((state) => state.setUserState);
        const location = useLocation();
        const { loading, error, user, emailVerified, isAuthenticated, getTokens, logout } = useAuth()
        const navigate = useNavigate()
        const setTheme = useThemeStore((state) => state.setTheme);

        useEffect(() => {
            setTheme()
        }, [location]);

        useEffect(() => {

            const prep = async () => {
                try {
                    const tokens = await getTokens();
                    const userSlice: UserAuthState = {
                        user: user,
                        userStatus: 'authenticated',
                        accessToken: tokens?.accessToken ?? null,
                        refreshToken: tokens?.refreshToken ?? null,
                        error: null,
                        role: role,
                        userId: userId,
                    };
                    Sentry.setTag("my-tag", "my value");
                    Sentry.setUser({
                        id: userId ?? 'unknown',
                        email: user.email ?? 'unknown',
                    });
                    setUserState(userSlice);
                } catch (error) {
                    await logout();
                    console.log(error)
                    toast.error(
                        'There has been an error getting authentication details. Please try logging in',
                    );
                    navigate('/auth/login')
                }
            };
            if (!loading && user) {
                prep();
            }

            if (error) {
                logout();
                console.log(error)
                toast.error(
                    'There has been an error getting authentication details. Please try logging in',
                );
                navigate('/auth/login')
            }
        }, [user, loading, error]);

        if (loading || loading) {
            return <Suspense fallback={<SuspenseLoader />}>
                <SuspenseLoader />
            </Suspense>
        }

        if (!isAuthenticated && !user && !loading && !loading) {
            // Redirect users to the login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            if (!userSlice || !userSlice?.user) {
                return (<Navigate to='/auth/login' state={{ from: location }} replace />);
            }
        }

        if ((!loading || !loading) && !emailVerified) {
            return (<Navigate to='/status/unverified' state={{ from: location }} replace />);
        }

        if (location.pathname.includes('/admin/') && role === 'proteusWorker') {
            return (<Navigate to='/worker/jobs' state={{ from: location }} replace />);
        }

    } catch (error) {
        //Route to page if anything failed here
        return (<Navigate to='/status/500' replace />);
    }

    return children;
};