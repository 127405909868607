import { Box, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type propsType = {
  open: boolean;
  onClose: () => void;
  text?: string;
};
export default function AppBackdrop({
  open = false,
  onClose = () => null,
  text = '',
}: propsType) {

  return (
    <div>
      <Backdrop sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: '#fff',
      }} open={open} onClick={onClose}>
        <Stack spacing={2} direction="column" alignItems="center">
          <Box>
            <img src={'/static/images/brand/loader.svg'} alt='Logo' width={'100px'} />
          </Box>
          <Box>
            <Typography variant="h4" color={'primary'}>{text}</Typography>
          </Box>
        </Stack>
      </Backdrop>
    </div>
  );
}
