import { HomeTwoTone, InfoTwoTone } from '@mui/icons-material';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, ListItem, Stack, Tooltip, Typography, lighten, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from 'src/components/LogoSign';
import { BaseDialog } from 'src/components/dialogs/BaseDialog';
import Instructions from 'src/components/instructions/Instructions';
import SystemStatus from 'src/components/systemStatus/SystemStatus';
import { useDialogStore } from 'src/store/dialog/dialogStore';
import { useSidebarStore } from 'src/store/sidebar/sidebarStore';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const LogoAnimationBox = styled(Box)(() => ({
    animation: 'logoShowSideBar 1s ease 0s 1 normal forwards',
    '@keyframes logoShowSideBar': {
        '0%': {
            opacity: 0,
            transform: 'rotateY(100deg)',
            transformOrigin: 'left',
        },
        '100%': {
            opacity: 1,
            transform: 'rotateY(0)',
            transformOrigin: 'left',
        },
    },
}));

const NavButton = styled(Button)(({ theme }) => ({
    justifyContent: 'left',
    '&.active': {
        border: `1px solid ${theme.palette.primary.main}`,
        animation: 'borderAnimation 2s ease',
    },
    '&:hover': {
        transform: 'scale(1.02)',
    },
    '@keyframes borderAnimation': {
        '0%': {
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
        },
        '20%': {
            borderTopColor: lighten(theme.palette.secondary.main, 0.5),
        },
        '50%': {
            borderLeftColor: lighten(theme.palette.secondary.main, 0.5),
        },
        '100%': {
            borderRightColor: theme.palette.secondary.main,
        },
    },
})) as typeof Button;

interface Props {
    shouldCloseOnClick?: boolean;
}

export default function SidebarContentActiveJob({ shouldCloseOnClick = false }: Props) {
    const theme = useTheme();
    const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
    const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
    const navigate = useNavigate();
    const openDialog = useDialogStore((state) => state.openDialog);
    const closeDialog = useDialogStore((state) => state.closeDialog);

    const handleNavButtonClick = () => {
        openDialog({
            dialogContent: (
                <Box>
                    <Typography variant='h6'>
                        Are you sure you want to go to home?
                    </Typography>
                    <Stack direction='row' spacing={2} justifyContent='center' sx={{ mt: 2 }}>
                        <LoadingButton
                            onClick={() => {
                                closeDialog()
                            }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => {
                                closeDialog()
                                navigate('/worker/home')
                            }}
                        >
                            Continue
                        </LoadingButton>
                    </Stack>
                </Box>
            )
        })
    }

    return (
        <>
            <BaseDialog />
            {isSidebarOpen &&
                <DrawerHeader id='sidebar-logo' sx={{ background: `linear-gradient(0deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.main} 80%)` }}>
                    <LogoAnimationBox>
                        <Logo width={250} />
                    </LogoAnimationBox>
                </DrawerHeader>
            }

            <Divider
                textAlign='right'
                id='sidebar-layout-divider'
                sx={{
                    mx: 1,
                    mt: isSidebarOpen ? 2 : `calc(${theme.header.height} + 20px)`,
                    alignItems: 'center'
                }}
            >
                <IconButton
                    color='secondary'
                    aria-label="open drawer"
                    onClick={() => toggleSidebar()}
                >
                    {isSidebarOpen ? <FirstPageRoundedIcon fontSize='medium' color='primary' id='sidebar-layout-close' /> : <LastPageRoundedIcon fontSize='medium' color='primary' id='sidebar-layout-open' />}
                </IconButton>
            </Divider>

            <Stack direction='column' justifyContent='space-between' sx={{ mt: 1.5, height: '100%' }}>
                <div>
                    {!isSidebarOpen && <Divider sx={{ my: 1, mx: 1 }} />}
                    {isSidebarOpen ?
                        <Instructions />
                        :
                        <ListItem component='div'>
                            <NavButton
                                fullWidth
                                onClick={() => toggleSidebar()}
                                startIcon={
                                    <Tooltip title='Show Instructions' placement='right'>
                                        <InfoTwoTone color='secondary' />
                                    </Tooltip>
                                }
                            >
                                {null}
                            </NavButton>
                        </ListItem>
                    }
                </div>
                <div>
                    <ListItem component='div'>
                        <NavButton
                            fullWidth
                            // component={RouterLink}
                            // to='/worker/home'
                            onClick={() => handleNavButtonClick()}
                            startIcon={
                                <Tooltip title='Back to Home' placement='right'>
                                    <HomeTwoTone color='secondary' fontSize='small' />
                                </Tooltip>
                            }
                        >
                            {isSidebarOpen ?
                                <Typography color='secondary' variant='body1'>Back to Home</Typography>
                                : null
                            }
                        </NavButton>
                    </ListItem>
                    <SystemStatus />
                </div>
            </Stack>
        </>
    );
}
