import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

class ApiClient {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({ baseURL });
    this.setupInterceptors();
  }

  private setupInterceptors() {
    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // You can add common headers or transformations here
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // You can process successful responses here
        return response;
      },
      (error: AxiosError) => {
        // Handle and process errors here
        return Promise.reject(error);
      }
    );
  }

  /**
   * Sends a GET request.
   * @param url The URL to request.
   * @param config Optional Axios request configuration.
   * @returns A Promise that resolves with the response data.
   */
  public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get(url, config).then((response) => response.data);
  }

  /**
   * Sends a POST request.
   * @param url The URL to request.
   * @param data The data to send in the request body.
   * @param config Optional Axios request configuration.
   * @returns A Promise that resolves with the response data.
   */
  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.post(url, data, config).then((response) => response.data);
  }

  /**
   * Sends a PATCH request.
   * @param url The URL to request.
   * @param data The data to send in the request body.
   * @param config Optional Axios request configuration.
   * @returns A Promise that resolves with the response data.
   */
  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.patch(url, data, config).then((response) => response.data);
  }


  /**
   * Sends a PUT request.
   * @param url The URL to request.
   * @param data The data to send in the request body.
   * @param config Optional Axios request configuration.
   * @returns A Promise that resolves with the response data.
   */
  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.put(url, data, config).then((response) => response.data);
  }

  /**
   * Sends a DELETE request.
   * @param url The URL to request.
   * @param config Optional Axios request configuration.
   * @returns A Promise that resolves with the response data.
   */
  public delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.delete(url, config).then((response) => response.data);
  }

  /**
* Downloads a file.
* @param url The URL to request for downloading the file.
* @param config Optional Axios request configuration.
* @returns A Promise that resolves when the download is complete.
*/
  public download(url: string, filename: string, config?: AxiosRequestConfig): Promise<void> {
    return this.axiosInstance
      .get(url, { ...config, responseType: 'blob' }) // Set responseType to 'blob' to handle binary data
      .then((response: AxiosResponse<Blob>) => {
        // Create a blob URL for the downloaded file
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);

        // Create a link to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the desired filename
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up by revoking the blob URL and removing the link
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });
  }
}

export default ApiClient;
