export enum TypesTags {

    JOB_CREATE = 'job_create',
    JOB_EDIT = 'job_edit',
    JOB_CLONE = 'info',
    TASK_SUBMIT = 'task_submit',
    QA_SUBMIT = 'qa_submit',
    TASK_GET = 'task_get',
    QA_GET = 'qa_get',

    GENERAL = 'general',
}