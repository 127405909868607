import { Typography } from "@mui/material";

type propsType = {
  msg: string | string[];
  inline?: boolean;
};


const ErrorMsg = ({ msg, inline = false }: propsType) => {
  return (
    <Typography
      sx={{
        color: "red",
      }}
      variant="caption"
      display={inline ? "inline" : "block"}
      gutterBottom
    >
      {msg}
    </Typography>
  );
};

export default ErrorMsg;
