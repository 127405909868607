import * as Sentry from '@sentry/react';
import { TypesTags } from 'src/models/sentry/sentry';

const logSentryError = (error: string, severity: Sentry.SeverityLevel, extra?: Record<string, any>, typeTag?: TypesTags) => {
    console.error('Sending Sentry Error:', error);
    Sentry.withScope(function (scope) {
        if (typeTag) {
            scope.setTag("type", typeTag);
            scope.setLevel(severity);
        }
        Sentry.captureException(new Error(error), {
            level: severity,
            extra: extra,
        });
    });
}

const logSentryMessage = (message: string, severity: Sentry.SeverityLevel, extra?: Record<string, any>, typeTag?: TypesTags) => {
    console.log('Sending Sentry Message:', message);
    Sentry.withScope(function (scope) {
        if (typeTag) {
            scope.setTag("type", typeTag);
            scope.setLevel(severity);
        }
        Sentry.captureMessage(message, {
            level: severity,
            extra: extra,
        });
    });

}

export { logSentryError, logSentryMessage };