import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as SvgIcons from './iconsFolder';

export type svgIcons = keyof typeof SvgIcons;
type IconsProps = {
  name: svgIcons;
} & SvgIconProps;

const Icon = (props: IconsProps) => {
  const { name, ...rest } = props;
  const Svg = SvgIcons[name];
  return (
    <SvgIcon {...rest}>
      <Svg {...rest} />
    </SvgIcon>
  );
};
export default Icon;
