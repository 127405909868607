import { Button, Paper, Tooltip } from '@mui/material';
import Icon from 'src/components/SVGIcons';
import { IThemeMode, themeNames, useThemeStore } from 'src/store/theme/themeStore';

/**
 * Renders a theme switch button that toggles between light and dark mode.
 */
const ThemeSwitch = () => {
  const toggleTheme = useThemeStore((state) => state.toggleThemeMode);
  const isDark = useThemeStore((state) => state.themeMode) === IThemeMode.dark;

  return (
    <Button onClick={() => toggleTheme()} size="small">
      <Tooltip arrow title={isDark ? 'Light Mode' : 'Dark Mode'}>
        {isDark ? <Icon name='LightModeIcon' /> : <Icon name='DarkModeIcon' />}
      </Tooltip>
    </Button>
  );
};

export default ThemeSwitch;
