import { InfoOutlined, RefreshOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Can } from "src/context/canContext";
import { env } from "src/env";
import { useGetSystemStatusQuery } from "src/services/restApi/queries/systemStatus.service";

type APIDialogInfo = {
  open: boolean;
  onCancel: any;
  appVersion: string;

};
const APIDialogInfo = ({
  open,
  onCancel,
  appVersion,
}: APIDialogInfo) => {
  const { data, isLoading, refetch, isFetching } = useGetSystemStatusQuery()

  const handleClose = () => {
    onCancel();
  };

  const [log, setLog] = useState<string>("");

  useEffect(() => {
    const storedLog = localStorage.getItem("log");
    if (storedLog) {
      setLog(storedLog);
    }
  }, []);

  const handleSetLog = () => {
    if (log === 'true') {
      setLog('false');
      localStorage.setItem("logs", 'false');
    } else {
      setLog('true');
      localStorage.setItem("logs", 'true');
    }

  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Proteus Status{" "}
        <RefreshOutlined
          fontSize="medium"
          onClick={() => refetch()}
          sx={
            isLoading || isFetching
              ? {
                color: "blue",
                animation: "spin 2s linear infinite",
                "@keyframes spin": {
                  "100%": {
                    transform: "rotate(360deg)",
                  },
                  "0%": {
                    transform: "rotate(0deg)",
                  },
                },
              }
              : { color: "green" }
          }
        />{" "}
      </DialogTitle>
      <DialogContent>
        <strong>App Version:</strong> {appVersion}
        <br />
        <strong>App Cloud Env:</strong> {env.REACT_APP_CLOUD_ENV}
        <br />
        <br />
        <strong>Release Name:</strong> {data?.releaseName ?? 'Unknown'}
        <br />
        <strong>Release Id:</strong> {data?.releaseId ?? 'Unknown'}
        <br />
        <strong>Hosted Environment:</strong> {data?.hostedEnvironment ?? 'Unknown'}
        <br />
        <br />
        <Can I="enable" a="logs">
          <>
            {env.REACT_APP_DEPLOYMENT_ENV === "production" &&
              <FormGroup>
                <FormControlLabel control={<Switch checked={log === 'true'} onChange={() => handleSetLog()} />} label="Show Logs (refresh required)" />
              </FormGroup>
            }
          </>
        </Can>
      </DialogContent>



      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface Props {
  session: any;
}

export default function SystemStatus() {
  const [apiDialogOpen, setApiDialogOpen] = useState<boolean>(false);
  const appVersion = env.REACT_APP_APP_VERSION ?? "--";
  return (
    <Box
      m={1}
      fontSize="x-small"
    >
      {appVersion}
      <InfoOutlined fontSize="inherit" onClick={() => setApiDialogOpen(true)} />
      <APIDialogInfo
        open={apiDialogOpen}
        onCancel={() => setApiDialogOpen(false)}
        appVersion={appVersion}
      />
    </Box>
  );
}
