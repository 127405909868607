export const getFriendlyUserType = (userType: 'proteusAdmin' | 'proteusCrowdManager' | 'proteusWorker' | 'proteusViewOnly' | null) => {
    if (userType === 'proteusAdmin') {
        return 'Admin';
    } else if (userType === 'proteusCrowdManager') {
        return 'Crowd Manager';
    } else if (userType === 'proteusWorker') {
        return 'Worker';
    } else if (userType === 'proteusViewOnly') {
        return 'View Only';
    } else {
        return 'TBD';
    }
};