import { Alert, Card, CardContent, Container, InputAdornment, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import globalStyles from "src/utils/constants/globalStyles";
import { useAuth } from "../..";
import AppBackdrop from "../../../../components/appBackdrop/AppBackdrop";
import ErrorMsg from "../../../../components/errorMessage/ErrorMsg";
import AppPassword from "../../../../components/inputs/AppPassword";
import Messages from "../../../../utils/Messages";
import { userIcon } from "../../../../utils/icons";
import { emailRegx, passwordRegx } from "../../../../utils/validators";
import { env } from "src/env";

const passwordPolicyTest: any = (value: string) => {
  return passwordRegx.test(value);
};

interface Props {
  email: string;
  tempPassword: string;
}

const Register = ({ email, tempPassword }: Props) => {
  const { register } = useAuth();

  const [errMsg, setErrormsg] = useState<React.ReactElement | null>(null);
  const [emailNotEncoded, setEmailNotEncoded] = useState<string>(email);
  const [tempPasswordNotEncoded, setTempPasswordNotEncoded] = useState<string>(tempPassword);

  useEffect(() => {
    setEmailNotEncoded(email);
    let temp = '';
    if (env.REACT_APP_CLOUD_ENV === 'aws') {
      if (env.REACT_APP_DEPLOYMENT_ENV === 'development') {
        temp = 'https://proteus.dev.voxcroft.com/auth/register/' + window.location.search;
      } else if (env.REACT_APP_DEPLOYMENT_ENV === 'staging') {
        temp = 'https://proteus.ppe.voxcroft.com/auth/register/' + window.location.search;
      } else if (env.REACT_APP_DEPLOYMENT_ENV === 'production') {
        temp = 'https://proteus.prd.voxcroft.com/auth/register/' + window.location.search;
      }
    }
    if (env.REACT_APP_CLOUD_ENV === 'gcp') {
      temp = tempPassword;
    }
    setTempPasswordNotEncoded(temp);
  }, [email]);


  return (
    <Card sx={{ ...globalStyles.card, textAlign: 'center', backgroundColor: 'white' }} elevation={20}>
      <Container maxWidth='xs' >
        <Typography variant='h2' sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
          Register
        </Typography>
      </Container>
      <CardContent style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Formik
          enableReinitialize
          initialValues={{
            oldPassword: tempPasswordNotEncoded,
            newPassword: "",
            comfirmPassword: "",
            firstName: "",
            surName: "",
            email: emailNotEncoded,
          }}
          validate={(values) => {
            const errors = {
              oldPassword: "",
              newPassword: "",
              comfirmPassword: "",
              firstName: "",
              surName: "",
              email: "",
            };
            if (!values.firstName) {
              errors.firstName = Messages.REQUIRED;
            }
            if (!values.surName) {
              errors.surName = Messages.REQUIRED;
            }
            if (!values.oldPassword) {
              errors.oldPassword = Messages.REQUIRED;
            }
            if (!values.email) {
              errors.email = Messages.REQUIRED;
            }
            if (!emailRegx.test(values.email)) {
              errors.email = Messages.INVALID_EMAIL_ADDRESS;
            }
            if (!values.newPassword) {
              errors.newPassword = Messages.REQUIRED;
            }
            if (!passwordPolicyTest(values.newPassword)) {
              errors.newPassword = Messages.PASSWORD_POLICY_MSG;
            }
            if (!values.comfirmPassword) {
              errors.comfirmPassword = Messages.REQUIRED;
            }
            if (values.comfirmPassword !== values.newPassword) {
              errors.comfirmPassword = Messages.PASSWORD_DONOT_MATCH;
            }
            if (
              !errors.oldPassword &&
              !errors.newPassword &&
              !errors.comfirmPassword &&
              !errors.firstName &&
              !errors.surName
            ) {
              return {};
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              console.log(values);
              setSubmitting(true);
              const res = await register(
                values.email,
                values.newPassword,
                values.oldPassword ?? window.location.href,
                values.firstName,
                values.surName,
                values.comfirmPassword
              );
              setSubmitting(false);
              setSubmitting(false);
            } catch (error: any) {
              setSubmitting(false);
              setErrormsg(<Alert severity="error">{error.message}</Alert>);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {errMsg && errMsg}
              <>
                <TextField
                  required
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  id='email'
                  type='email'
                  autoComplete="username"
                  label="Email"
                  sx={{ mb: 1 }}
                  inputProps={{
                    readOnly: email ? true : false,
                  }}
                  disabled={email ? true : false}
                  placeholder=""
                  helperText={
                    email ? "" : "Please use same email as on your contract"
                  }
                  fullWidth
                  InputProps={{
                    error: Boolean(errors.email && touched.email && errors.email),
                    startAdornment: (
                      <InputAdornment position="start">{userIcon}</InputAdornment>
                    ),
                  }}
                />
                {errors.email && touched.email && <ErrorMsg msg={errors.email} />}
                <TextField
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="firstName"
                  sx={{ mb: 1 }}
                  label="First name"
                  autoComplete="off"
                  placeholder=""
                  helperText="Please use same firstname as on your contract"
                  fullWidth
                />
                {errors.firstName && touched.firstName && (
                  <ErrorMsg msg={errors.firstName} />
                )}
                <TextField
                  required
                  value={values.surName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="surName"
                  label="Surname"
                  sx={{ mb: 1 }}
                  placeholder=""
                  helperText="Please use same surname as on your contract"
                  fullWidth
                  autoComplete="off"
                />
                {errors.surName && touched.surName && (
                  <ErrorMsg msg={errors.surName} />
                )}
              </>
              <br />

              <AppPassword
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="standard"
                sx={{ mb: 1 }}
                margin="normal"
                required
                type="password"
                fullWidth
                name="newPassword"
                label="New Password"
                id="newPassword"
                autoComplete="password"
              />
              {errors.newPassword && touched.newPassword && (
                <ErrorMsg msg={errors.newPassword} />
              )}
              <br />

              <AppPassword
                value={values.comfirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="standard"
                margin="normal"
                sx={{ mb: 1 }}
                required
                fullWidth
                name="comfirmPassword"
                label="Confirm Password"
                id="comfirmPassword"
                autoComplete="off"
              />
              {errors.comfirmPassword && touched.comfirmPassword && (
                <ErrorMsg msg={errors.comfirmPassword} />
              )}
              <br />
              <br />

              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                Confirm Details and Password
              </LoadingButton>
              <AppBackdrop open={isSubmitting} onClose={() => null} />
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default Register;
