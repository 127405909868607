import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface SidebarState {
  isSidebarOpen: boolean;

  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      isSidebarOpen: true,
      openSidebar: () => set({ isSidebarOpen: true }),
      closeSidebar: () => set({ isSidebarOpen: false }),
      toggleSidebar: () => set((state) => ({
        isSidebarOpen: state.isSidebarOpen ? false : true,
      }))
    }),

    // The key used to save the values of the store in localStorage.
    { name: 'sidebarStore' },
  ),
)

