import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { ReactNode, useEffect } from 'react';
import { useThemeStore } from 'src/store/theme/themeStore';
import { themeCreator } from '../base';

export const ThemeContext = React.createContext((themeName: string): void => { });

interface IThemeProviderWrapper {
  children: ReactNode;
}

/**
 * Wrapper component for the theme provider.
 * @param props - The component props.
 * @returns The rendered component.
 */
const ThemeProviderWrapper: React.FC<IThemeProviderWrapper> = (props: any) => {
  const theme = useThemeStore((state) => state.theme);
  const setTheme = useThemeStore((state) => state.setTheme);

  /**
   * Sets the theme name.
   * @param themeName - The name of the theme.
   */
  const setThemeName = (): void => {
    setTheme()
  };

  useEffect(() => {
    setThemeName();
  }, [theme]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={themeCreator(theme)}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
