import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUserAuthStore } from 'src/store/userAuth/userAuthStore';
import { AuthContext } from '../../context/AuthContextInterface';
import { _getCurrentUser } from './actions/_getCurrentUser';
import { _getCognitoUserSession } from './actions/_getSession';
import { cognitoForgotPassword } from './actions/cognitoForgotPassword';
import { cognitoForgotPasswordConfirmStart } from './actions/cognitoForgotPasswordConfirm';
import { cognitoRegistration } from './actions/cognitoRegisterAccount';
import { loginWithEmailAndPasswordCognito } from './actions/loginWithEmailAndPassword';
import refreshAccessToken from './actions/refreshToken';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const CognitoAuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const setUserState = useUserAuthStore((state) => state.setUserState);
  const setAuthError = useUserAuthStore((state) => state.setError);

  useEffect(() => {
    const prep = async () => {
      const currentUser = _getCurrentUser()
      if (currentUser) {
        const session = await _getCognitoUserSession()
        if (session) {
          setUser(session.idToken.payload)
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    try {
      prep();
    } catch (error) {
      handleError(error)
    }

  }, []);

  const loginWithEmailAndPassword = async (email: string, password: string, inApp?: boolean) => {
    try {
      const cognitoUser = await loginWithEmailAndPasswordCognito(email, password)

      setUserState(cognitoUser)
      setUser(cognitoUser.user)
      if (inApp) {
        return { error: false }
      }
    } catch (error) {
      if (inApp) {
        toast.error('Error Authenticating...');
        return { error: true }
      } else {
        setAuthError('Error Authenticating...');
        handleError(error);
      }

    }
  };

  const loginWithGoogle = async () => {
    throw new Error('Method not Implemented');
  };

  const logout = async () => {
    logoutCognito()
    setUser(null);

  };

  const register = async (email: string, newPassword: string, oldPassword: string, firstName: string, surName: string, comfirmPassword: string) => {
    const res = await cognitoRegistration(
      {
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
        firstName: firstName,
        lastName: surName
      }
    )
    if (res.error) {
      handleError(res.message)
    } else {
      const resetUserState = useUserAuthStore.getState().resetUserState;
      resetUserState();
      logoutCognito()
      setUser(null);
      window.location.href = '/auth/login'
    }
    return { error: true, message: res.message };
  };

  const refreshToken = async () => {
    throw new Error('Method not Implemented');
  };

  const getTokens = async () => {
    const currentUser = _getCurrentUser()
    if (currentUser) {
      const session = await _getCognitoUserSession()
      return {
        accessToken: session.idToken.jwtToken ?? null,
        refreshToken: session.refreshToken.token ?? null
      }
    } else {
      setLoading(false);
      handleError(error);
      return null;
    }
  };

  const verifyEmail = async () => {
    throw new Error('Method not Implemented');
  };

  const resetPassword = async (email: string) => {
    const res = await cognitoForgotPassword({ email: email })
    return res;
  };

  const resetPasswordConfirm = async (email: string, verificationCode: string, newPassword: string) => {
    const res = await cognitoForgotPasswordConfirmStart({ email, verificationCode, newPassword })
    return res;
  };

  const handleError = (error: any) => {
    // Handle and display the error
    toast.error('An authentication error has occurred. Please check you details..');
    console.log('Authentication Error:', error);
    setError(error);
  };

  const isAuthenticated = !!user;

  const emailVerified = true //TODO

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        error,
        getTokens,
        loginWithEmailAndPassword,
        loginWithGoogle,
        register,
        logout,
        refreshToken,
        verifyEmail,
        resetPassword,
        resetPasswordConfirm,
        emailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const refreshCognitoToken = async () => {
  return await refreshAccessToken()
}

export const logoutCognito = async () => {
  const currentUser = _getCurrentUser()
  if (currentUser) {
    await currentUser.signOut();
    useUserAuthStore.getState().resetUserState();
  }
  window.location.href = '/auth/login'
}