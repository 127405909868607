import { Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled } from '@mui/material/styles';
import appConfig from 'src/config/appConfig';
import SidebarContentAdmin from './SidebarContentAdmin';
import SidebarContentWorker from './SidebarContentWorker';
import HiddenWrapper from 'src/components/componentWrappers/HiddenWrapper';
import { useLocation } from 'react-router-dom';
import { useSidebarStore } from 'src/store/sidebar/sidebarStore';
import SidebarContentActiveJob from './SidebarContentActiveJob';

const openedMixin = (theme: Theme): CSSObject => ({
    width: theme.sidebar.width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up(appConfig.breakMobileView)]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: theme.sidebar.width,
        flexShrink: 0,
        // whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MobileDrawer = styled(MuiDrawer,)({
    zIndex: 10000,
});

export default function SidebarLayout() {
    const location = useLocation()

    const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
    const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);

    const handleDrawerToggle = () => {
        toggleSidebar()
    };

    return (
        <>
            <HiddenWrapper desktopOnly>
                <DesktopDrawer
                    id="desktop-drawer"
                    className='desktop-drawer'
                    variant="permanent"
                    open={isSidebarOpen}
                >
                    {location.pathname.includes('admin')
                        &&
                        <SidebarContentAdmin />
                    }
                    {location.pathname.includes('worker') && !location.pathname.includes('/worker/job/')
                        &&
                        <SidebarContentWorker />
                    }
                    {location.pathname.includes('/worker/job/')
                        &&
                        <SidebarContentActiveJob />
                    }
                </DesktopDrawer>
            </HiddenWrapper>
            <HiddenWrapper mobileOnly>
                <MobileDrawer
                    id="mobile-drawer"
                    className='mobile-drawer'
                    variant="temporary"
                    open={isSidebarOpen}
                    onClose={() => handleDrawerToggle()}
                >
                    {location.pathname.includes('admin')
                        &&
                        <SidebarContentAdmin />
                    }
                    {location.pathname.includes('worker') && !location.pathname.includes('/worker/job/')
                        &&
                        <SidebarContentWorker />
                    }
                    {location.pathname.includes('/worker/job/')
                        &&
                        <SidebarContentActiveJob />
                    }
                </MobileDrawer>
            </HiddenWrapper>
        </>
    )
}
