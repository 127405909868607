export default {
  REQUIRED: 'This field is required!',
  INVALID_EMAIL_ADDRESS: 'Invalid email address!',
  PASSWORD_POLICY_MSG: 'Password must be at least 8 characters long,\n must contain at least 1 upper case,\n 1 lower case,\n 1 numeric character\n and at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =)!',
  EMAILS_SEP_COMMA: 'Input must be valid emails separated by comma!',
  INVALID_EMAIL_ADDRESSES: 'Input must be a valid email address!',

  UPLOAD_SRC_FILE: 'Source file dataset is required!',
  SOMETHING_WENT_WRONG: 'Something went wrong! Please Try again.',
  NETWORK_ERROR: 'Network error!',
  PASSWORD_DONOT_MATCH: 'Password do not match!',
  INVALID_JOB_NAME:
    'Job name can only contain letters of the alphabet with underscores and digits, And must start with alphabet. No spaces are permitted!',
  DUPLICATE_ENTRY: 'Duplicate values supplied!',
};
