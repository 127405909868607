import { Box, Typography } from "@mui/material";
import footerLogo from "../../assets/footerLogo.png";

const Footer = () => {

  return (
    <Box bgcolor="#396376" sx={{
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "10rem",
      marginTop: "8rem",
      paddingLeft: 5,
      paddingTop: 1,
    }}>
      <span style={{
        cursor: "pointer",
        margin: 10,
        width: 290,
        height: 50,
      }}>
        <img
          src={footerLogo}
          alt="Proteus"
          height={55}
          width={200}
        />
      </span>
      <hr style={{ border: "1px solid #df5730" }} />
      <br />
      <Typography variant="body1" sx={{
        color: "white",
        paddingLeft: 2,
      }}>
        © 2023 VoxCroft. All rights reserved ·{" "}
        <a
          href={"https://www.voxcroft.com/privacy/"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "inherit" }}
        >
          Privacy
        </a>{" "}
        ·{" "}
        <a
          href={"https://www.voxcroft.com/terms/"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "inherit" }}
        >
          Terms
        </a>
      </Typography>
    </Box>
  );
};

export default Footer;
