import { JobInformationSchemaVM } from 'src/models/jobTask.model';
import { create } from 'zustand';

export interface WorkerInstructionState {
  activeInstruction: 'primary' | 'qa';
  information: JobInformationSchemaVM | null;

  setActiveInstruction: (instruction: 'primary' | 'qa') => void;
  setInformation: (information: JobInformationSchemaVM | null) => void;
}

export const useWorkerInstructionsStore = create<WorkerInstructionState>((set) => ({
  activeInstruction: 'primary',
  information: null,

  setActiveInstruction: (instruction) => set({ activeInstruction: instruction }),
  setInformation: (information) => set({ information }),
}))