import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { UserAuthState } from 'src/store/userAuth/userAuthStore';
import { _getCognitoUser } from './_getCognitoUser';

const asyncAuthenticateUser = (
    cognitoUser: CognitoUser,
    cognitoAuthenticationDetails: AuthenticationDetails
) => {
    return new Promise<any>(function (resolve, reject) {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onSuccess: resolve,
            onFailure: reject,
        });
    });
}

export const loginWithEmailAndPasswordCognito = async (email: string, password: string) => {
    try {
        const authenticationData = {
            Username: email,
            Password: password,
        }

        const authenticationDetails = new AuthenticationDetails(authenticationData)
        const cognitoUser = _getCognitoUser(email)
        cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
        const res: any = await asyncAuthenticateUser(cognitoUser, authenticationDetails);
        console.log(res)
        const user: UserAuthState = {
            user: res.idToken.payload,
            userStatus: 'authenticated',
            accessToken: res.accessToken.jwtToken,
            refreshToken: res.refreshToken.token,
            error: null,
            role: res.idToken.payload['cognito:groups'] ? authCognitoGroups(res.idToken.payload['cognito:groups']) : 'proteusWorker',
            userId: res.idToken.payload.sub,
        };
        return user;
    } catch (error: any) {
        console.log(error)
        throw new Error('error logging in with cognito');
    }
}

/**
 * Checks if the user has the correct groups/attributes to access the app.
 *
 * @param {string[]} groups - The groups of the user.
 * @param {string} attribute - The attribute to check.
 * @return {string} The role of the user based on their groups/attributes.
 * @throws {Error} If the user does not belong to any needed cognito groups to access Proteus.
 */
const authCognitoGroups = (groups: string[]) => {
    //Checks if the user has the correct groups / attributes to access the app
    if (groups && groups.includes("proteusAdmin")) {
        return "proteusAdmin";
    } else if (groups && groups.includes("proteusCrowdManager")) {
        return "proteusCrowdManager";
    } else if (groups && groups.includes("proteusViewOnly")) {
        return "proteusViewOnly";
    } else if (groups && groups.includes("proteusWorker")) {
        return "proteusWorker";
    } else {
        console.log(
            "User does not belong in any needed cognito groups to access proteus"
        );
        throw new Error(
            "Not authorised! You do not belong in the necessary groups to access Proteus."
        );
    }
};