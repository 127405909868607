import { Result } from 'src/models/baseApi.model';
import { TAG, TagId } from '../config/enums';
import { baseApi } from './baseApi.service';

const systemStatusApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // ############################# GET JOB METRICS NEXT REFRESH TIME #############################
        getJobMetricsNextRefreshTime: builder.query<Result, void>({
            extraOptions: {
                useToken: true,
            },
            query: () => ({
                url: '/SystemStatus/JobMetricsNextRefreshTime',
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: TAG.SYSTEM_STATUS, id: TagId.Detail }],
        }),
        // ############################# GET SYSTEM STATUS #############################
        getSystemStatus: builder.query<any, void>({
            extraOptions: {
                useToken: true,
            },
            query: () => ({
                url: '/SystemStatus',
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.data
            },
            providesTags: (result, error, id) => [{ type: TAG.SYSTEM_STATUS, id: TagId.Detail }],
        }),
    })
})

export const {
    useGetJobMetricsNextRefreshTimeQuery,
    useGetSystemStatusQuery
} = systemStatusApi;
